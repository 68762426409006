<template>
  <div class="d-flex flex-column pa-1">
    <v-snackbar
      v-model="snackbar_success"
      :color="colorSnackbar"
      multi-line="multi-line"
      :timeout="6000"
      bottom="bottom"
    >
      <v-icon small color="#FFF" class="mr-3">mdi-check</v-icon>
      {{ message }}
    </v-snackbar>
    <v-dialog v-model="intoFollow" width="45vw">
      <v-card>
        <v-card-title>Follow up NPS</v-card-title>
        <v-card-subtitle>
          <div class="d-flex  justify-space-between align-center">
            <span>Cliente: {{ itemFollow.NOME }}</span>
            <span>Nota: {{ itemFollow.RESPOSTA }}</span>
          </div>
          <div class="d-flex  justify-space-between align-center">
            <span>Time: {{ itemFollow.TIME }}</span>
            <span>Profissional: {{ itemFollow.PROFISSIONAL }}</span>
          </div>
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-textarea
                outlined
                label="Digite o follow-Up do cliente"
                v-model="textFollowUp"
              ></v-textarea>
            </v-col>
          </v-row>

          <div class="mb-2">
            <v-btn
              :disabled="itemsHistFollow.length <= 0"
              class="info"
              x-small
              @click="infoHistFollow = !infoHistFollow"
              >{{
                infoHistFollow
                  ? "Esconder histórico de Follow-up"
                  : "Ver histórico de Follow-up"
              }}</v-btn
            >
          </div>
          <v-expand-transition>
            <div style="height: 350px; overflow: scroll;" v-if="infoHistFollow">
              <v-timeline align-top dense>
                <v-timeline-item
                  v-for="(item, i) in itemsHistFollow"
                  :key="i"
                  :color="item.color"
                  :icon="item.icon"
                  fill-dot
                >
                  <v-card :color="item.color" dark>
                    <v-card-title class="text-h6">
                      <div class="d-flex justify-space-between align-center">
                        <span>{{ item.owner }}</span>
                        <span>{{ item.date }}</span>
                        <span>{{ item.hour }}</span>
                      </div>
                    </v-card-title>
                    <v-card-text class="white text--primary">
                      <p>
                        {{ item.text }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </div>
          </v-expand-transition>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-btn class="error" x-small>Fechar</v-btn>
          <v-spacer></v-spacer>
          <v-btn class="primary" small @click="salvaFollowNPS()">Gravar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogItensNF" persistent width="20vw">
      <v-card>
        <v-card-title>Itens da NF, aguarde...</v-card-title>
        <v-card-subtitle>Buscando...</v-card-subtitle>
        <v-card-text class="d-flex justify-center align-center">
          <v-progress-circular
            :rotate="-90"
            :size="100"
            :width="15"
            :value="QtdOpimed"
            color="primary"
          >
            {{ QtdOpimed }}
          </v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAwaitJob" width="30vw" persistent>
      <v-card>
        <v-overlay :value="dialogAwaitJob" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-title class="headline grey lighten-2"
          >Notificação whatsApp</v-card-title
        >
        <v-card-subtitle>
          <p class="body-1">Aguarde processando...</p>
        </v-card-subtitle>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEdit" persistent width="40vw">
      <v-card>
        <v-card-title>Alteração de dados de notificação</v-card-title>
        <v-card-subtitle>{{ dadosEditaCliente }}</v-card-subtitle>
        <v-card-text>
          <v-row dense class="justify-center align-center">
            <v-col cols="2">
              <v-text-field
                label="DDD"
                v-model="ddd"
                dense
                :counter="2"
                :maxlength="2"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Telefone"
                v-model="telefone"
                dense
                :counter="9"
                :maxlength="9"
              ></v-text-field>
            </v-col>
            <v-col cols="6" v-if="origem === 'agenda'">
              <v-autocomplete
                v-model="localAtendimento"
                :items="itemsLocaisAtendimento"
                item-text="ZZ2_LOCAL"
                item-value="ZZ2_CODIGO"
                dense
                label="Local de atendimento"
                hide-no-data
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn class="error" x-small @click="dialogEdit = !dialogEdit"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn class="primary" x-small @click="salvaEdicao">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="d-flex justify-space-around align-center pa-2">
      <v-radio-group v-model="origem" row>
        <v-radio
          v-for="card in cards"
          :key="card.id"
          :label="card.title"
          :value="card.id"
          @change="alteraOrigem"
        ></v-radio>
      </v-radio-group>
    </div>
    <div style="width: 95vw;">
      <v-data-table
        dense
        :headers="headerTable"
        :items="itemsTable"
        hide-default-footer
        :loading="loading"
        :search="search"
        class="elevation-1 tbLeads"
        no-data-text="Sem dados..."
        fixed-header
        height="72vh"
        :items-per-page="itemsTable.length"
      >
        <template v-slot:[`item.STATUS_AGENDA`]="{ item }">
          <v-chip
            class="ma-2"
            x-small
            color="green darken-2"
            dark
            v-if="item.STATUS_AGENDA === 'COMPARECEU'"
          >
            {{ item.STATUS_AGENDA }}
          </v-chip>
          <v-chip
            class="ma-2"
            x-small
            color="red darken-2"
            dark
            v-if="item.STATUS_AGENDA === 'NÃO COMPARECEU'"
          >
            {{ item.STATUS_AGENDA }}
          </v-chip>
          <v-chip
            class="ma-2"
            x-small
            color="blue darken-2"
            dark
            v-if="item.STATUS_AGENDA === 'BLOQUEADA'"
          >
            {{ item.STATUS_AGENDA }}
          </v-chip>
          <v-chip
            class="ma-2"
            x-small
            color="amber darken-2"
            dark
            v-if="item.STATUS_AGENDA === 'AGENDADO'"
          >
            {{ item.STATUS_AGENDA }}
          </v-chip>
        </template>
        <template v-slot:[`item.RESPOSTA_AGENDA`]="{ item }">
          <div v-if="!item.RESPOSTA_AGENDA">
            <span>-</span>
          </div>
          <div
            v-if="item.RESPOSTA_AGENDA === 'Confirmado'"
            class="d-flex justify-center align-center"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ml-2" v-bind="attrs" v-on="on" color="green" dark
                  >mdi-thumb-up</v-icon
                >
              </template>
              <span>{{ item.RESPOSTA_AGENDA }}</span>
            </v-tooltip>
          </div>

          <div
            v-if="item.RESPOSTA_AGENDA === 'Reagendar'"
            class="d-flex justify-center align-center"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ml-2" v-bind="attrs" v-on="on" color="blue" dark
                  >mdi-calendar-account-outline</v-icon
                >
              </template>
              <span>{{ item.RESPOSTA_AGENDA }}</span>
            </v-tooltip>
          </div>

          <div
            v-if="item.RESPOSTA_AGENDA === 'Cancelar'"
            class="d-flex justify-center align-center"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ml-2" v-bind="attrs" v-on="on" color="red" dark
                  >mdi-thumb-down</v-icon
                >
              </template>
              <span>{{ item.RESPOSTA_AGENDA }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.RESPOSTA`]="{ item }">
          <div
            v-if="item.RESPOSTA && origem === 'nps'"
            class="d-flex justify-space-around align-center"
          >
            {{ item.RESPOSTA }}

            <v-icon
              large
              color="red"
              v-if="item.RESPOSTA >= 1 && item.RESPOSTA <= 2"
              >mdi-emoticon-angry-outline</v-icon
            >
            <v-icon
              large
              color="orange"
              v-if="item.RESPOSTA >= 3 && item.RESPOSTA <= 4"
              >mdi-emoticon-sad-outline</v-icon
            >
            <v-icon
              large
              color="yellow darken-1"
              v-if="item.RESPOSTA >= 5 && item.RESPOSTA <= 6"
              >mdi-emoticon-neutral-outline</v-icon
            >
            <v-icon
              large
              color="green"
              v-if="item.RESPOSTA >= 7 && item.RESPOSTA <= 8"
              >mdi-emoticon-happy-outline</v-icon
            >
            <v-icon
              large
              color="blue"
              v-if="item.RESPOSTA >= 9 && item.RESPOSTA <= 10"
              >mdi-emoticon-excited-outline</v-icon
            >

            <!-- <v-icon large color="red" >mdi-emoticon-angry-outline</v-icon>
            <v-icon large color="orange">mdi-emoticon-sad-outline</v-icon>
            <v-icon large color="yellow darken-1">mdi-emoticon-neutral-outline</v-icon>
            <v-icon large color="green" >mdi-emoticon-happy-outline</v-icon>
            <v-icon large color="blue" >mdi-emoticon-excited-outline</v-icon> -->
          </div>
          <div v-else class="d-flex justify-space-around align-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                  color="success"
                  dark
                  v-if="item.RESPOSTA === '1'"
                  >mdi-thumb-up</v-icon
                >
              </template>
              <span>Já esta agendado</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                  dark
                  v-if="item.RESPOSTA === '2'"
                  >mdi-thumb-down</v-icon
                >
              </template>
              <span>Não agendou</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.APARELHOS`]="{ item }">
          <div class="d-flex flex-column justify-center align-center">
            <v-chip
              color="blue lighten-4"
              v-for="(produto, i) in item.APARELHOS"
              :key="i"
              class="ma-2"
              x-small
            >
              {{ produto.PRODUTO }}
            </v-chip>
          </div>
        </template>
        <template v-slot:[`item.TEMPLATE`]="{ item }">
          <div class="d-flex justify-space-around align-center">
            <v-chip
              :color="item.COLOR"
              :text-color="!item.DARK ? 'black' : 'white'"
              class="ma-2"
              x-small
            >
              {{ item.TEMPLATE }}
            </v-chip>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :disabled="item.PROCESSOU === 'S' && item.ERRO === 'N'"
                  v-bind="attrs"
                  v-on="on"
                  @click="editaEvento(item)"
                >
                  <v-icon color="green darken-3">mdi-text-box-edit</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :disabled="!item.EDITOU"
                  v-bind="attrs"
                  v-on="on"
                  @click="enviaNotificacao(item)"
                >
                  <v-icon color="blue darken-3">mdi-play-circle</v-icon>
                </v-btn>
              </template>
              <span>Enviar bot</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-if="false"
                  :disabled="!item.RESPOSTA"
                  v-bind="attrs"
                  v-on="on"
                  @click="criaFollowUp(item)"
                >
                  <v-icon color="pink darken-2">mdi-account-convert</v-icon>
                </v-btn>
              </template>
              <span>Follow Up</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.PROCESSOU`]="{ item }">
          <v-chip
            class="ma-2"
            x-small
            color="green darken-2"
            dark
            v-if="item.PROCESSOU === 'S'"
          >
            Sim
          </v-chip>
          <v-chip class="ma-2" x-small color="red darken-2" dark v-else>
            Não
          </v-chip>
        </template>
        <template v-slot:[`item.ERRO`]="{ item }">
          <div v-if="item.PROCESSOU === 'S'">
            <v-chip
              class="ma-2"
              x-small
              color="green darken-2"
              dark
              v-if="item.ERRO === 'N'"
            >
              Não
            </v-chip>
            <v-chip class="ma-2" x-small color="red darken-2" dark v-else>
              Sim
            </v-chip>
          </div>
        </template>
        <template v-slot:top>
          <v-row class="d-flex align-center">
            <v-col cols="1">
              <v-text-field
                v-if="origem !== 'Opimed+'"
                class="mt-4 text-uppercase"
                label="Data Inicial"
                v-model="dtInicial"
                v-mask="'##/##/####'"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-else
                class="mt-4 text-uppercase"
                label="Data Referencia"
                v-model="dtInicial"
                v-mask="'##/##/####'"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-text-field
                v-if="origem !== 'Opimed+'"
                class="mt-4 text-uppercase"
                label="Data Final"
                v-model="dtFinal"
                v-mask="'##/##/####'"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-btn class="primary" x-small @click="carregaDados"
              >Carregar</v-btn
            >
            <v-spacer></v-spacer>
            <v-badge
              v-if="itemsTable.length > 1"
              color="purple darken-1"
              :content="itemsTable.length"
            >
              Total
            </v-badge>
            <v-spacer></v-spacer>
            <v-badge
              v-if="itemsTable.length > 1"
              color="cyan darken-1"
              :content="
                itemsTable.filter(e => e.PROCESSOU === 'S' && e.ERRO === 'N')
                  .length
              "
            >
              Enviados
            </v-badge>
            <v-spacer></v-spacer>
            <span v-if="itemsTable.length > 1">
              Taxa de envio em
              {{
                (
                  (itemsTable.filter(e => e.PROCESSOU === "S" && e.ERRO === "N")
                    .length *
                    100) /
                  itemsTable.length
                ).toFixed(2)
              }}%
            </span>
            <v-spacer></v-spacer>
            <v-tooltip v-if="origem === 'agenda'" bottom>
              <v-spacer></v-spacer>
              <template v-slot:activator="{ on, attrs }">
                <vue-excel-xlsx
                  :data="itemsRelatExcel"
                  :columns="headersRelatExcel"
                  filename="Rel. Agenda"
                  :sheetname="``"
                  class="ml-5 mb-5 tbExcel"
                >
                  <v-btn
                    :disabled="!origem === 'agenda'"
                    icon
                    color="blue darken-2"
                    class="mr-5 mt-5"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </vue-excel-xlsx>
              </template>
              <span>Exportar para excel</span>
            </v-tooltip>
            <v-tooltip v-if="origem === 'nps'" bottom>
              <template v-slot:activator="{ on, attrs }">
                <vue-excel-xlsx
                  :data="itemsRelatExcel"
                  :columns="headersRelatExcel"
                  filename="Pesq. Nps"
                  :sheetname="``"
                  class="ml-5 mb-5 tbExcel"
                >
                  <v-btn
                    :disabled="origem === 'nps' ? false : true"
                    icon
                    color="blue darken-2"
                    class="mr-5 mt-5"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </vue-excel-xlsx>
              </template>
              <span>Exportar para excel</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-col cols="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
              ></v-text-field>
            </v-col> </v-row></template
      ></v-data-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import { mapState } from "vuex";

export default {
  name: "Home_WhatsApp",
  computed: mapState(["user"]),
  data: () => ({
    infoHistFollow: false,
    itemsHistFollow: [],
    intoFollow: false,
    itemFollow: {
      NOME: "",
      RESPOSTA: "",
      PROFISSIONAL: "",
      TIME: "",
    },
    textFollowUp: "",
    itemsRelatExcel: [],
    headersRelatExcel: [],
    sizeOpimed: 0,
    dialogItensNF: false,
    QtdOpimed: 0,
    snackbar_success: false,
    colorSnackbar: "success",
    message: null,
    dialogAwaitJob: false,
    dialogEdit: false,
    origem: "agenda",
    search: null,
    dtInicial: "",
    dtFinal: "",
    cards: [
      {
        id: "agenda",
        title: "Agendamento",
        icon: {
          color: "blue",
          icon: "mdi-calendar-account-outline",
          size: "36",
        },
      },
      {
        id: "nps",
        title: "Pesquisa NPS",
        icon: {
          color: "cyan",
          icon: "mdi-account-group",
          size: "36",
        },
      },
      {
        id: "Opimed+",
        title: "Opimed+",
        icon: {
          color: "yellow darken-4",
          icon: "mdi-gift-outline",
          size: "36",
        },
      },
    ],
    headerTable: [],
    itemsTable: [],
    loading: false,
    ddd: null,
    telefone: null,
    localAtendimento: null,
    itemsLocaisAtendimento: [],
    dadosEditaCliente: null,
    indexEditado: -1,
    diasOpimed: [],
  }),
  methods: {
    async salvaFollowNPS() {
      console.log(this.user);
      console.log(this.itemFollow);

      const dados = {
        Z4_DATA: dayjs().format("YYYYMMDD"),
        Z4_HORA: dayjs().format("HH:mm"),
        Z4_ALIAS: "NPS",
        Z4_TIPO: "17",
        Z4_STATUS: "03",
        Z4_TEXTO: this.textFollowUp,
        Z4_MSFIL: "0101",
        Z4_RESP1: this.user.usr_protheus,
        Z4_NOME: this.user.name,
      };

      console.log(dados);
    },
    async criaFollowUp(item) {
      this.itemFollow = item;
      const url = `${process.env.VUE_APP_BASE_API_URL}/newGetFollowUp`;

      const histFollow = await axios.post(url, {
        tipo: "NPS",
        cliente: item.CLIENTE,
        loja: item.LOJA,
      });

      if (histFollow.status !== 200) {
        console.log("erro ao consultar a base");
      }

      if (histFollow.data.length <= 0) {
        this.itemsHistFollow = [];
        this.intoFollow = true;
        return;
      }

      this.itemsHistFollow = [
        histFollow.data.map(e => {
          return {
            color: "cyan",
            icon: "mdi-account-convert",
            text: e.Z4_TEXTO,
            date: e.Z4_DATA,
            hour: e.Z4_HORA,
            owner: e.Z4_USERINC,
          };
        }),
      ];
      this.intoFollow = true;
    },
    alteraOrigem() {
      this.headerTable = [];
      this.itemsTable = [];
      this.dtInicial = "";
      this.dtFinal = "";
    },
    async enviaNotificacao(item) {
      this.indexEditado = this.itemsTable.indexOf(item);

      let dados = {};
      let dadosAtualiza = {};

      this.dialogAwaitJob = true;

      if (this.origem === "agenda") {
        dados = {
          origem: "agendamento",
          item,
        };

        dadosAtualiza = {
          contexto: { ZZL_ID: item.IDENTIFICADOR, CLIENTE: null },
          dados: {
            ZZL_ERWHAT: "N",
            ZZL_WHATS: "S",
          },
        };
      }

      if (this.origem === "nps") {
        if (item.TIME === "Equipe comercial") {
          item.IDENTIFICADOR = `${item.IDENTIFICADOR.trim()}-SD2`;
          dados = {
            origem: "equipe comercial",
            item,
          };
        }
        if (item.TIME === "Equipe fono") {
          item.IDENTIFICADOR = `${item.IDENTIFICADOR.trim()}-ZZL`;
          dados = {
            origem: "agenda fono",
            item,
          };
        }

        dadosAtualiza = {
          contexto: { ZZL_ID: item.IDENTIFICADOR.split("-")[0], CLIENTE: null },
          dados: {
            ZZL_ZAPPOS: "S",
            ZZL_EZAPPO: "N",
          },
        };
      }

      if (this.origem === "Opimed+") {
        item.IDENTIFICADOR = `${item.IDENTIFICADOR.trim()}-SF2`;
        dados = {
          origem: "Opimed+",
          item,
        };
      }

      let url = `${process.env.VUE_APP_BASE_API_URL}/enviaNotificacao`;

      try {
        await axios.post(url, dados);
      } catch (error) {
        console.log(error);
        this.colorSnackbar = "error";
        this.snackbar_success = true;
        if (error.response.status === 500) {
          this.message = error.response.data;
        }
        if (error.response.status === 400) {
          this.message = error.response.data.description;
        }
        this.dialogAwaitJob = false;
        return;
      }

      if (this.origem !== "Opimed+") {
        url = `${process.env.VUE_APP_BASE_API_URL}/atualizaAgendamento`;

        await axios
          .post(url, dadosAtualiza)
          .then(() => {
            this.colorSnackbar = "success";
            this.snackbar_success = true;
            this.message = `Enviado com sucesso!`;
          })
          .catch(err => console.log(err));
      }

      if (this.indexEditado > -1) {
        Object.assign(this.itemsTable[this.indexEditado], {
          ERRO: "N",
          PROCESSOU: "S",
          EDITOU: false,
        });
      }

      this.dialogAwaitJob = false;
    },
    async salvaEdicao() {
      const item = this.itemsTable[this.indexEditado];

      this.colorSnackbar = "success";
      this.snackbar_success = true;
      this.message = `Atualizado com sucesso!`;

      if (this.origem === "agenda") {
        const dados = {
          contexto: { ZZL_ID: item.IDENTIFICADOR, CLIENTE: item.CODIGO },
          dados: {
            ZZL_TEL1: this.telefone,
            ZZL_DDD: this.ddd,
            ZZL_LOCAL: this.localAtendimento,
          },
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/atualizaAgendamento`;

        await axios
          .post(url, dados)
          .then(() => {
            this.colorSnackbar = "success";
            this.snackbar_success = true;
            this.message = `Atualizado com sucesso!`;
          })
          .catch(err => console.log(err));

        const localAtendi = this.itemsLocaisAtendimento.filter(
          e => e.ZZ2_CODIGO === this.localAtendimento,
        );
        if (this.indexEditado > -1) {
          Object.assign(this.itemsTable[this.indexEditado], {
            DDD: this.ddd,
            TELEFONE: this.telefone,
            LOCAL_ATEND: localAtendi[0].ZZ2_LOCAL,
            EDITOU: true,
          });
        }
      }

      if (this.origem === "nps") {
        const dados = {
          contexto: { ZZL_ID: item.IDENTIFICADOR, CLIENTE: item.CLIENTE },
          dados: {
            ZZL_TEL1: this.telefone,
            ZZL_DDD: this.ddd,
          },
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/atualizaAgendamento`;

        await axios
          .post(url, dados)
          .then(() => {
            this.colorSnackbar = "success";
            this.snackbar_success = true;
            this.message = `Atualizado com sucesso!`;
          })
          .catch(err => console.log(err));

        if (this.indexEditado > -1) {
          Object.assign(this.itemsTable[this.indexEditado], {
            DDD: this.ddd,
            TELEFONE: this.telefone,
            EDITOU: true,
          });
        }
      }

      if (this.origem === "Opimed+") {
        const dados = {
          SF2: { R_E_C_N_O_: item.IDENTIFICADOR },
          dados: {
            A1_COD: item.COD_CLI,
            A1_LOJA: "01",
            A1_TEL: this.telefone,
            A1_DDD: this.ddd,
            A1_ZWHATSA: this.telefone,
          },
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/atualizaOpimed`;

        await axios
          .post(url, dados)
          .then(() => {
            this.colorSnackbar = "success";
            this.snackbar_success = true;
            this.message = `Atualizado com sucesso!`;
          })
          .catch(err => console.log(err));

        if (this.indexEditado > -1) {
          Object.assign(this.itemsTable[this.indexEditado], {
            DDD: this.ddd,
            TELEFONE: this.telefone,
            EDITOU: true,
          });
        }
      }

      this.indexEditado = -1;
      this.dialogEdit = false;
    },
    async editaEvento(item) {
      this.dadosEditaCliente = null;
      this.ddd = item.DDD;
      this.telefone = item.TELEFONE;

      if (this.origem === "agenda") {
        this.dadosEditaCliente = `${item.CLIENTE} - ${item.HORA_AGENDA} - ${item.PROFISSIONAL}`;
        if (this.itemsLocaisAtendimento.length <= 0) {
          this.dialogAwaitJob = true;
          const dados = {
            EMPRESA: "0101",
            FILIAL: "01",
            FIELDS: "ZZ2_CODIGO,ZZ2_LOCAL",
            WHERE: `ZZ2_ATIVO='S'`,
            TABELA: "ZZ2",
          };

          const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

          await axios
            .post(url, dados)
            .then(res => {
              this.itemsLocaisAtendimento = res.data;
            })
            .catch(err => console.log(err));
        }

        this.dialogAwaitJob = false;
        if (item.LOCAL_ATEND) {
          const localAtende = this.itemsLocaisAtendimento.filter(
            e => e.ZZ2_LOCAL === item.LOCAL_ATEND,
          );
          this.localAtendimento = localAtende[0].ZZ2_CODIGO;
        }
      }

      if (this.origem === "nps" || this.origem === "Opimed+") {
        this.dadosEditaCliente = `${item.NOME} - ${item.EMISSAO} - ${item.NF}/${item.SERIE}`;
      }

      this.indexEditado = this.itemsTable.indexOf(item);

      this.dialogEdit = true;
    },
    async carregaDados() {
      if (this.origem !== "Opimed+") {
        if (!this.dtInicial || !this.dtFinal) return;
      } else {
        if (!this.dtInicial) return;
      }
      this.loading = true;
      const dti = this.dtInicial.split("/");
      const dtf = this.dtFinal.split("/");

      if (this.origem === "agenda") {
        this.carregaAgendamento(dti, dtf);
      }

      if (this.origem === "nps") {
        this.carregaNPS(dti, dtf);
      }
      if (this.origem === "Opimed+") {
        this.carregaOpimed(dti);
      }
    },
    async carregaAgendamento(dti, dtf) {
      let dados = {};
      this.headerTable = [];
      this.itemsTable = [];
      const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `
             ZZL.R_E_C_N_O_ IDENTIFICADOR,
             ZZL_TITULO TITULO,
             ZZL_DDD DDD,
             ZZL_TEL1 TELEFONE,
             ZZL_DTINI DATA_AGENDA,
             ZZL_HRINI HORA_AGENDA,
             CASE WHEN ZZL_STATUS = 'C' THEN 'COMPARECEU'
                WHEN ZZL_STATUS = 'S' THEN 'BLOQUEADA'
                WHEN ZZL_STATUS = 'A' THEN 'AGENDADO'
                ELSE 'NÃO COMPARECEU' END STATUS_AGENDA,
             CASE WHEN A1_NOME = '' THEN '' ELSE A1_COD END CODIGO,
             CASE WHEN A1_NOME = '' THEN ZZL_NOMECL ELSE A1_NOME END CLIENTE,
             ZZK_COD COD_PROF,
             ZZK_NOME PROFISSIONAL,
             ZZ2_LOCAL LOCAL_ATEND,
             ZZL_WHATS PROCESSOU,
             ZZL_ERWHAT ERRO,
             IIF(ZZZ_CTXTO = 'agendamento', ZZZ_RESPO, '') RESPOSTA_AGENDA`,
        WHERE: `ZZL_DTINI BETWEEN '${dti[2]}${dti[1]}${dti[0]}' AND '${dtf[2]}${dtf[1]}${dtf[0]}'
                  AND ZZL_TITULO <> 'agenda bloqueada'
                  ORDER BY ZZL_DTINI`,
        INNER: `LEFT JOIN SA1010 SA1 WITH(NOLOCK) ON
                                    SA1.D_E_L_E_T_=' '
                                    AND A1_COD=ZZL_CLIENT
                                    AND A1_LOJA=ZZL_LOJA
                    INNER JOIN ZZK010 ZZK WITH(NOLOCK) ON
                                    ZZK.D_E_L_E_T_=' '
                                    AND ZZK_COD NOT IN ('000001','000131','000135','000029')
                                    AND ZZK_COD=ZZL_PESSOA
                    LEFT JOIN ZZ2010 ZZ2 WITH(NOLOCK) ON
                                    ZZ2.D_E_L_E_T_=' '
                                    AND ZZ2_CODIGO=ZZL_LOCAL
                                    AND ZZ2_ATIVO='S'
                    LEFT JOIN ZZZ010 ZZZ WITH(NOLOCK) ON
                                    ZZZ.D_E_L_E_T_=' '
                                    AND ZZZ_ID=CONVERT(VARCHAR(20),ZZL.R_E_C_N_O_)+'-ZZL' `,
        TABELA: "ZZL",
      };

      const agendamentos = await axios.post(url, dados);

      if (agendamentos.data.length <= 0) {
        this.loading = false;
        return;
      }

      this.headerTable = Object.keys(agendamentos.data[0]).map(key => {
        return { text: key, value: key };
      });

      this.headersRelatExcel = Object.keys(agendamentos.data[0]).map(key => {
        return { label: key, field: key };
      });

      const respostasAgenda = {
        "1": "Confirmado",
        "2": "Reagendar",
        "3": "Cancelar",
      };

      const agenda = agendamentos.data.map(e => ({
        ...e,
        EDITOU: false,
        DATA_AGENDA: `${e.DATA_AGENDA.substr(6, 2)}/${e.DATA_AGENDA.substr(
          4,
          2,
        )}/${e.DATA_AGENDA.substr(0, 4)}`,
        RESPOSTA_AGENDA: respostasAgenda[e.RESPOSTA_AGENDA],
      }));

      this.headerTable.push({ text: "Ações", value: "actions" });

      this.itemsTable = agenda;
      this.itemsRelatExcel = agenda;

      this.loading = false;
    },
    async carregaNPS(dti, dtf) {
      this.headerTable = [];
      this.itemsTable = [];
      const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      const dados1 = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `A3_FILREF CODFIL,M0_FILIAL FILIAL,SD2.R_E_C_N_O_ IDENTIFICADOR,'Equipe comercial' TIME,A3_NOME PROFISSIONAL,D2_EMISSAO EMISSAO,D2_DOC NF,D2_SERIE SERIE,D2_CLIENTE CLIENTE,D2_LOJA LOJA,A1_NOME NOME,ZZZ_RESPO RESPOSTA,A1_TEL TELEFONE,A1_DDD DDD,D2_ZZWHATS PROCESSOU,D2_ZZERWHA ERRO`,
        INNER: `INNER JOIN SF4010 SF4 WITH(NOLOCK) ON
                                SF4.D_E_L_E_T_=' '
                                AND F4_CODIGO=D2_TES
                                AND F4_DUPLIC='S'
                INNER JOIN SF2010 SF2 WITH(NOLOCK) ON
                                SF2.D_E_L_E_T_=' '
                                AND F2_DOC=D2_DOC
                                AND F2_SERIE=D2_SERIE
                                AND F2_CLIENTE=D2_CLIENTE
                                AND F2_LOJA=D2_LOJA

                INNER JOIN SA3010 SA3 WITH(NOLOCK) ON
                                SA3.D_E_L_E_T_=' '
                                AND A3_COD=F2_VEND1

                LEFT JOIN SYS_COMPANY SYSCOMP WITH(NOLOCK) ON SYSCOMP.D_E_L_E_T_=' '
                                AND M0_CODFIL=A3_FILREF

                INNER JOIN SA1010 SA1 WITH(NOLOCK) ON
                                SA1.D_E_L_E_T_=' '
                                AND A1_COD=D2_CLIENTE
                                AND A1_LOJA=D2_LOJA
                                AND A1_PESSOA='F'
                LEFT JOIN ZZZ010 ZZZ WITH(NOLOCK) ON
                                ZZZ.D_E_L_E_T_=' '
                                AND SUBSTRING(ZZZ_ID,0,7) = SD2.R_E_C_N_O_
                                AND SUBSTRING(ZZZ_ID,8,3) = 'SD2'
                                AND ZZZ_CTXTO='pesquisa' `,
        WHERE: `SD2.D_E_L_E_T_=' ' AND D2_EMISSAO BETWEEN '${dti[2]}${dti[1]}${dti[0]}' AND '${dtf[2]}${dtf[1]}${dtf[0]}' AND D2_GRUPO IN ('1005','2001') AND D2_ZZWHATS = 'S' ORDER BY D2_CLIENTE`,
        TABELA: "SD2",
      };

      const pesquisas1 = await axios.post(url, dados1);

      if (pesquisas1.data.length <= 0) {
        this.loading = false;
        return;
      }

      this.headerTable = Object.keys(pesquisas1.data[0]).map(key => {
        return { text: key, value: key };
      });

      this.headersRelatExcel = Object.keys(pesquisas1.data[0]).map(key => {
        return { label: key, field: key };
      });

      pesquisas1.data.map(e => (e.EDITOU = false));
      pesquisas1.data.map(e => (e.RESPOSTA ? parseInt(e.RESPOSTA) : null));
      pesquisas1.data.map(
        e =>
          (e.EMISSAO = `${e.EMISSAO.substr(6, 2)}/${e.EMISSAO.substr(
            4,
            2,
          )}/${e.EMISSAO.substr(0, 4)}`),
      );

      const dados2 = {
        FIELDS: `DISTINCT ZZK_FILREF CODFIL,M0_FILIAL FILIAL,ZZL.R_E_C_N_O_ IDENTIFICADOR,'Equipe fono' TIME,ZZL_DDD DDD,ZZL_TEL1 TELEFONE,ZZL_DTINI EMISSAO,'-' NF,'-' SERIE,ZZL_HRINI HORA_AGENDA,A1_NOME NOME,ZZK_NOME PROFISSIONAL,ZZZ_RESPO RESPOSTA,A1_COD CLIENTE,A1_LOJA LOJA,ZZK_COD COD_PROF,ZZL_ZAPPOS PROCESSOU,ZZL_EZAPPO ERRO`,
        INNER: `INNER JOIN SA1010 SA1 WITH(NOLOCK) ON
                                SA1.D_E_L_E_T_=' '
                                AND A1_COD=ZZL_CLIENT
                                AND A1_LOJA=ZZL_LOJA
                INNER JOIN ZZK010 ZZK WITH(NOLOCK) ON
                                ZZK.D_E_L_E_T_=' '
                                AND ZZK_COD NOT IN ('000001','000131','000135','000029')
                                AND ZZK_COD=ZZL_PESSOA

                LEFT JOIN SYS_COMPANY SYSCOMP WITH(NOLOCK) ON SYSCOMP.D_E_L_E_T_=' '
                                AND M0_CODFIL=ZZK_FILREF

                LEFT JOIN ZZ2010 ZZ2 WITH(NOLOCK) ON
                              ZZ2.D_E_L_E_T_=' '
                              AND ZZ2_CODIGO=ZZL_LOCAL
                              AND ZZ2_ATIVO='S'
                LEFT JOIN ZZZ010 ZZZ WITH(NOLOCK) ON
                              ZZZ.D_E_L_E_T_=' '
                              AND SUBSTRING(ZZZ_ID,0,7) = ZZL.R_E_C_N_O_
                              AND SUBSTRING(ZZZ_ID,8,3) = 'ZZL'
                              AND ZZZ_CTXTO='pesquisa' `,
        WHERE: `ZZL_DTINI BETWEEN '${dti[2]}${dti[1]}${dti[0]}' AND '${dtf[2]}${dtf[1]}${dtf[0]}'
                AND ZZL_STATUS ='C'
                AND ZZL_CLIENT<>''
                AND ZZL_TITULO<>'agenda bloqueada'`,
        TABELA: "ZZL",
      };

      const pesquisas2 = await axios.post(url, dados2);
      pesquisas2.data.map(e => (e.EDITOU = false));
      pesquisas2.data.map(e => (e.RESPOSTA ? parseInt(e.RESPOSTA) : null));
      pesquisas2.data.map(
        e =>
          (e.EMISSAO = `${e.EMISSAO.substr(6, 2)}/${e.EMISSAO.substr(
            4,
            2,
          )}/${e.EMISSAO.substr(0, 4)}`),
      );

      const dados3 = {
        FIELDS: `DISTINCT ZZK_FILREF CODFIL,M0_FILIAL FILIAL,ZZL.R_E_C_N_O_ IDENTIFICADOR,'Equipe fono' TIME,ZZL_DDD DDD,ZZL_TEL1 TELEFONE,ZZL_DTINI EMISSAO,'-' NF,'-' SERIE,ZZL_HRINI HORA_AGENDA,ZZL_NOMECL NOME,ZZK_NOME PROFISSIONAL,ZZZ_RESPO RESPOSTA,A1_COD CLIENTE,A1_LOJA LOJA,ZZK_COD COD_PROF,ZZL_ZAPPOS PROCESSOU,ZZL_EZAPPO ERRO`,
        INNER: `INNER JOIN SA1010 SA1 WITH(NOLOCK) ON
                                SA1.D_E_L_E_T_=' '
                                AND A1_COD=ZZL_CLIENT
                                AND A1_LOJA=ZZL_LOJA
                INNER JOIN ZZK010 ZZK WITH(NOLOCK) ON
                                ZZK.D_E_L_E_T_=' '
                                AND ZZK_COD NOT IN ('000001','000131','000135','000029')
                                AND ZZK_COD=ZZL_PESSOA

                LEFT JOIN SYS_COMPANY SYSCOMP WITH(NOLOCK) ON SYSCOMP.D_E_L_E_T_=' '
                                AND M0_CODFIL=ZZK_FILREF

                LEFT JOIN ZZ2010 ZZ2 WITH(NOLOCK) ON
                              ZZ2.D_E_L_E_T_=' '
                              AND ZZ2_CODIGO=ZZL_LOCAL
                              AND ZZ2_ATIVO='S'
                LEFT JOIN ZZZ010 ZZZ WITH(NOLOCK) ON
                              ZZZ.D_E_L_E_T_=' '
                              AND SUBSTRING(ZZZ_ID,0,7) = ZZL.R_E_C_N_O_
                              AND SUBSTRING(ZZZ_ID,8,3) = 'ZZL'
                              AND ZZZ_CTXTO='pesquisa' `,
        WHERE: `ZZL_DTINI BETWEEN '${dti[2]}${dti[1]}${dti[0]}' AND '${dtf[2]}${dtf[1]}${dtf[0]}'
                AND ZZL_STATUS ='C'
                AND ZZL_CLIENT=''
                AND ZZL_TITULO LIKE '%25primeira vez%25'`,
        TABELA: "ZZL",
      };

      const pesquisas3 = await axios.post(url, dados3);
      pesquisas3.data.map(e => (e.EDITOU = false));
      pesquisas3.data.map(e => (e.RESPOSTA ? parseInt(e.RESPOSTA) : null));
      pesquisas3.data.map(
        e =>
          (e.EMISSAO = `${e.EMISSAO.substr(6, 2)}/${e.EMISSAO.substr(
            4,
            2,
          )}/${e.EMISSAO.substr(0, 4)}`),
      );

      console.log(pesquisas1.data);
      console.log(pesquisas2.data);
      console.log(pesquisas3.data);

      this.itemsTable = [
        ...pesquisas1.data,
        ...pesquisas2.data,
        ...pesquisas3.data,
      ];
      this.itemsRelatExcel = [
        ...pesquisas1.data,
        ...pesquisas2.data,
        ...pesquisas3.data,
      ];

      this.headerTable.push({ text: "Ações", value: "actions" });

      this.loading = false;
    },
    async carregaOpimed(dti) {
      this.headerTable = [];
      this.itemsTable = [];
      this.dialogItensNF = false;
      this.QtdOpimed = 0;
      this.sizeOpimed = 0;
      if (this.diasOpimed.length <= 0) {
        this.origem = "nps";
      }
      const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;
      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `DISTINCT
                    SF2.R_E_C_N_O_ IDENTIFICADOR,
                    F2_EMISSAO EMISSAO,
                    F2_DOC NF,
                    F2_SERIE SERIE,
                    '' APARELHOS,
                    A3_COD+'-'+A3_NOME VENDEDOR,
                    A1_COD COD_CLI,
                    A1_NOME CLIENTE,
                    A1_TEL TELEFONE,
                    A1_DDD DDD,
                    '' TEMPLATE,
                    ZZZ_RESPO RESPOSTA,
                    DATEDIFF(DAY, CONVERT(DATE, F2_EMISSAO), CONVERT(VARCHAR(8), '${dti[2]}${dti[1]}${dti[0]}',112)) DIFF_DATA,
                    F2_ZZWHATS PROCESSOU,
                    F2_ZZERWHA ERRO `,
        INNER: `INNER JOIN SD2010 SD2 WITH(NOLOCK) ON
                                  SD2.D_E_L_E_T_=' '
                                  AND D2_DOC=F2_DOC
                                  AND D2_SERIE=F2_SERIE
                                  AND D2_CLIENTE=F2_CLIENTE
                                  AND D2_LOJA=F2_LOJA
                                  AND D2_GRUPO='1001'
                        INNER JOIN SF4010 SF4 WITH(NOLOCK) ON
                                  SF4.D_E_L_E_T_=' '
                                  AND F4_CODIGO=D2_TES
                                  AND F4_DUPLIC='S'
                                  AND F4_ESTOQUE='S'
                                  AND SUBSTRING(F4_CF,0,3)='51'
                        INNER JOIN SA1010 SA1 WITH(NOLOCK) ON
                                  SA1.D_E_L_E_T_=' '
                                  AND A1_COD=F2_CLIENTE
                                  AND A1_LOJA=F2_LOJA

                        INNER JOIN SA3010 SA3 WITH(NOLOCK) ON
                                  SA3.D_E_L_E_T_=' '
                                  AND A3_COD=F2_VEND1

                        LEFT JOIN ZZZ010 ZZZ WITH(NOLOCK) ON
                                        ZZZ.D_E_L_E_T_=' '
                                        AND SUBSTRING(ZZZ_ID,0,7) = SF2.R_E_C_N_O_
                                        AND SUBSTRING(ZZZ_ID,8,3) = 'SF2'
                                        AND ZZZ_CTXTO='opimed' `,
        WHERE: `SF2.D_E_L_E_T_=' '
                AND DATEDIFF(DAY, CONVERT(DATE, F2_EMISSAO), CONVERT(VARCHAR(8), '${dti[2]}${dti[1]}${dti[0]}',112)) IN (${this.diasOpimed[0].X6_CONTEUD})`,
        TABELA: "SF2",
      };

      const opimed = await axios.post(url, dados);

      if (opimed.data.length <= 0) {
        this.loading = false;
        return;
      }

      this.headerTable = Object.keys(opimed.data[0]).map(e => {
        return {
          text: e,
          value: e,
        };
      });

      this.headerTable = this.headerTable.filter(e => e.text !== "DIFF_DATA");

      opimed.data.map(e => (e.EDITOU = false));

      opimed.data.map(e => (e.RESPOSTA ? parseInt(e.RESPOSTA) : null));

      opimed.data.map(
        e =>
          (e.EMISSAO = `${e.EMISSAO.substr(6, 2)}/${e.EMISSAO.substr(
            4,
            2,
          )}/${e.EMISSAO.substr(0, 4)}`),
      );

      this.headerTable.push({ text: "Ações", value: "actions" });

      const dias = this.diasOpimed[0].X6_CONTEUD.split(",");

      opimed.data.map(e => {
        if (e.DIFF_DATA === parseInt(dias[0])) {
          e.TEMPLATE = "ACLIMATIZAÇÃO";
          e.COLOR = "orange lighten-4";
          e.DARK = false;
          e.contexto = "aclimatacao_new";
          e.state = "6141c18d-f873-4ff7-bfcc-5a1b20a05d90";
        }
        if (e.DIFF_DATA === parseInt(dias[1])) {
          e.TEMPLATE = "CONSULTA TRÊS MESES";
          e.COLOR = "orange lighten-3";
          e.DARK = false;
          e.contexto = "tres_meses_new";
          e.state = "815de483-de55-4120-8f73-ab2dacec6cc1";
        }
        if (e.DIFF_DATA === parseInt(dias[2])) {
          e.TEMPLATE = "CONSULTA SEIS MESES";
          e.COLOR = "orange lighten-2";
          e.DARK = false;
          e.contexto = "seis_meses_new";
          e.state = "fee0c21e-1b9e-496c-984b-f13458d0cb82";
        }
        if (e.DIFF_DATA === parseInt(dias[3])) {
          e.TEMPLATE = "CONSULTA UM ANO";
          e.COLOR = "orange lighten-1";
          e.DARK = false;
          e.contexto = "um_ano_new";
          e.state = "9e7e8f31-f02d-4659-8171-8550c9e0593e";
        }
        if (e.DIFF_DATA === parseInt(dias[4])) {
          e.TEMPLATE = "CONSULTA UM ANO E SEIS MESES";
          e.COLOR = "orange darken-1";
          e.DARK = true;
          e.contexto = "um_ano_seis_meses_new";
          e.state = "5d921e73-c303-4c51-8e20-8e0e99b653a6";
        }
        if (e.DIFF_DATA === parseInt(dias[5])) {
          e.TEMPLATE = "CONSULTA DOIS ANOS";
          e.COLOR = "orange darken-2";
          e.DARK = true;
          e.contexto = "dois_anos_new";
          e.state = "35b41eb7-d5c5-43eb-98b8-e6263aa4bc25";
        }
        if (e.DIFF_DATA === parseInt(dias[6])) {
          e.TEMPLATE = "CONSULTA DOIS ANOS E SEIS MESES";
          e.COLOR = "orange darken-3";
          e.DARK = true;
          e.contexto = "dois_anos_seis_meses_new";
          e.state = "a1014b76-0897-4b43-a354-249bc9b948d3";
        }
        if (e.DIFF_DATA === parseInt(dias[7])) {
          e.TEMPLATE = "CONSULTA TRÊS ANOS";
          e.COLOR = "orange darken-4";
          e.DARK = true;
          e.contexto = "tres_anos_new_1";
          e.state = "47077066-d520-4555-89f4-5b9f8a568f1d";
        }
      });

      this.QtdOpimed = 0;

      this.sizeOpimed = 100;
      this.dialogItensNF = true;

      await Promise.all(
        opimed.data.map(async item => {
          dados = {
            FIELDS: `RTRIM(B1_COD)+'-'+RTRIM(B1_DESC) PRODUTO,D2_QUANT`,
            INNER: `INNER JOIN SB1010 SB1 WITH(NOLOCK) ON
                                      SB1.D_E_L_E_T_=' '
                                      AND B1_COD=D2_COD `,
            WHERE: `SD2.D_E_L_E_T_=' '
                  AND D2_DOC='${item.NF}'
                  AND D2_SERIE='${item.SERIE}'
                  AND D2_CLIENTE='${item.COD_CLI}'
                  AND D2_LOJA='01'
                  AND D2_GRUPO='1001'`,
            TABELA: "SD2",
          };

          const itens = await axios.post(url, dados);

          opimed.data
            .filter(e => e.IDENTIFICADOR === item.IDENTIFICADOR)
            .map(e => (e.APARELHOS = itens.data));
          this.QtdOpimed++;
        }),
      );

      this.dialogItensNF = false;

      this.itemsTable = opimed.data;

      this.loading = false;
    },
    async buscaParamOpimed() {
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "X6_CONTEUD",
        WHERE: `X6_VAR='MV_ZZEVNPS'`,
        TABELA: "SX6",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      const dias = await axios.post(url, dados);

      this.diasOpimed = dias.data;
    },
  },
  mounted() {
    this.buscaParamOpimed();
  },
};
</script>

<style></style>
